<template>
  <v-card>
    <v-card-title>
      <span class="pl-0 primary--text">{{ formEditTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-form
        v-model="isFormValid"
        ref="formCotizador"
        form="formCotizador"
        id="formCotizador"
        @submit.prevent="saveEdit()"
      >
        <v-row>
          <v-col cols="3" sm="3" md="3" class="py-0">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaSelected"
                  label="Fecha de cotización"
                  :append-icon="calendarIcon"
                  v-bind="attrs"
                  outlined
                  dense
                  hint="Formato DD/MM/AAAA"
                  v-mask="'##/##/####'"
                  onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                  @blur="fecha = parseDateToIso(fechaSelected)"
                  v-on="on"
                  :disabled="itemsIntegrante.length !== 0"
                  :rules="rules.required.concat(rules.validDate)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fecha"
                no-title
                @change="fechaSelected = formatDate(fecha)"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- Vendedores -->
          <v-col cols="4" class="py-0">
            <v-autocomplete
              v-model="vendedoresSelected"
              :items="itemsVendedores"
              label="Vendedor"
              item-text="value"
              item-value="id"
              :rules="rules.required"
              return-object
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <!-- Nombre -->
          <v-col cols="5" class="py-0">
            <v-text-field
              v-model.trim="nombreDestinatario"
              label="Nombre destinatario"
              outlined
              dense
              :rules="
                rules.required.concat([
                  rules.requiredTrim(nombreDestinatario),
                  rules.maxLength(nombreDestinatario, 20)
                ])
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- convenios -->
          <v-col cols="4" class="py-0">
            <v-autocomplete
              v-model="convenioSelected"
              :items="itemsConvenio"
              label="Convenio"
              item-text="value"
              item-value="id"
              :rules="rules.required"
              :disabled="itemsIntegrante.length !== 0"
              @change="setPlanesByConvenios"
              return-object
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="1" md="1" class="justify-center pt-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="primary--text" v-on="on" v-bind="attrs">{{
                  infoIcon
                }}</v-icon>
              </template>
              <span
                >Convenios relacionados al formato de impresión de la
                cotización</span
              >
            </v-tooltip>
          </v-col>
          <!-- planes -->
          <v-col cols="4" class="py-0">
            <v-autocomplete
              v-model="planSelected"
              :items="itemsPlanes"
              label="Plan"
              item-text="value"
              item-value="id"
              :disabled="itemsIntegrante.length !== 0"
              :rules="rules.required"
              return-object
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <!-- Telefono -->
          <v-col cols="3" class="py-0">
            <v-text-field
              v-model.trim="telefono"
              label="Teléfono"
              outlined
              dense
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              :rules="
                rules.required.concat([
                  rules.requiredTrim(telefono),
                  rules.maxLength(telefono, 20)
                ])
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- Mail -->
          <v-col cols="5" class="py-0">
            <v-text-field
              v-model.trim="mail"
              label="Email"
              outlined
              dense
              :rules="
                rules.required.concat([rules.maxLength(mail, 100)], rules.email)
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="py-0" sm="12">
            <h5 class="pl-0 primary--text">
              Integrantes:
            </h5>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="py-0 pb-5 text-center">
            <v-data-table
              dense
              :items="itemsIntegrante"
              :headers="
                enviarCategoria
                  ? headersIntegranteConCategoria
                  : headersIntegranteSinCategoria
              "
              class="elevation-1"
              no-data-text="No hay integrantes"
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-btn
                        color="primary"
                        @click="toggleModalIntegrante()"
                        :disabled="
                          !convenioSelected || !planSelected || !fechaSelected
                        "
                        class="to-right"
                        small
                      >
                        Nuevo integrante
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`body.append`]="{ headers }">
                <tr class="totales text-end">
                  <td v-for="(header, i) in headers" :key="i">
                    <div v-if="header.value == 'edad'">
                      Total
                    </div>
                    <div v-if="header.value == 'valor'">
                      <strong> {{ totalIntegrantes }}</strong>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      small
                      @click="deleteIntegrante(item)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar integrante</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="py-0" sm="12">
            <h5 class="pl-0 primary--text">
              Reglas comerciales:
            </h5>
          </v-col>
          <v-col class="pt-0 pb-3">
            <v-data-table
              dense
              :headers="headersReglasCom"
              :items="formattedItems"
              class="elevation-1"
              no-data-text="No hay reglas comerciales"
              hide-default-footer
            >
              <template v-slot:[`item.acumulable`]="{ item }">
                <v-icon
                  v-if="item.acumulable"
                  small
                  color="primary"
                  class="d-flex justify-center"
                >
                  {{ checkIcon }}
                </v-icon>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-btn
                        color="primary"
                        @click="toggleReglaComercial()"
                        :disabled="itemsIntegrante.length === 0"
                        class="to-right"
                        small
                      >
                        Nueva regla comercial
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.importe`]="{ item }">
                {{ item.formattedImporte }}
              </template>
              <template v-slot:[`body.append`]="{ headers }">
                <tr class="totales text-end">
                  <td v-for="(header, i) in headers" :key="i">
                    <div v-if="header.value == 'cantidad'">
                      Total
                    </div>
                    <div v-if="header.value == 'importe'">
                      <strong> {{ totalReglasComerciales }}</strong>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      small
                      @click="deleteReglaComercial(item)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar regla comercial</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="pt-3">
          <!-- Resultado cotizacion -->
          <v-col cols="3" md="3" class="py-0">
            <v-text-field
              v-model.trim="rtdoCotizacion"
              label="Cotización total parcial"
              outlined
              persistent-hint
              disabled
              filled
              readonly
              dense
              class="right-input"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="convenioSelected ? convenioSelected.bool : false">
          <v-col cols="3" class="py-0">
            <v-autocomplete
              v-model="bonifOrRecargoSelected"
              :items="itemsTipoBonif"
              label="Bonificación/Recargo"
              item-text="value"
              item-value="id"
              outlined
              :disabled="itemsReglasCom.some(x => x.tipo.value1 === 'P')"
              @change="changeBonifRecargo"
              clearable
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3" class="py-0">
            <currency-input
              v-model.trim="porcentaje"
              label="Porcentaje"
              :disabled="
                !bonifOrRecargoSelected ||
                  itemsReglasCom.some(x => x.tipo.value1 === 'P')
              "
              :options="currencyOptionsForPorcentaje"
              persistent-hint
              :append-icon="porcentajeIcon"
              :rules="[]"
              @input="calcularCotizacion"
              class="right-input"
            >
            </currency-input>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-text-field
              v-model.trim="calculo"
              label="Cálculo"
              outlined
              persistent-hint
              filled
              :disabled="itemsReglasCom.some(x => x.tipo.value1 === 'P')"
              readonly
              dense
              class="right-input"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- Aporte esperado -->
          <v-col cols="3" class="py-0">
            <currency-input
              v-model="aporteEsperado"
              label="Aporte esperado"
              :options="currencyOptions"
              :disabled="itemsIntegrante.length === 0"
              :rules="itemsIntegrante.length !== 0 ? rules.required : []"
              class="right-input"
              @input="calcularCotizacion"
            ></currency-input>
          </v-col>
          <!-- Redondeo -->
          <v-col cols="12" md="2" class="py-0">
            <v-autocomplete
              outlined
              dense
              v-model="redondeoSelect"
              :items="redondeoItems"
              item-text="value"
              item-value="id"
              @change="calcularCotizacion"
              :rules="rules.required"
              label="Redondeo"
              return-object
            ></v-autocomplete>
          </v-col>
          <!-- Importe redondeo -->
          <v-col cols="2" md="2" class="py-0">
            <v-text-field
              v-model="importeRedondeo"
              label="Importe redondeo"
              class="right-input"
              outlined
              disabled
              filled
              readonly
              persistent-hint
              dense
            ></v-text-field>
          </v-col>
          <!-- Resultado cotizacion -->
          <v-col cols="3" md="3" class="py-0">
            <v-text-field
              v-model.trim="rtdoCotizacionFinal"
              label="Cotización total final"
              class="right-input"
              outlined
              disabled
              filled
              readonly
              persistent-hint
              dense
            ></v-text-field>
          </v-col>
          <!-- fecha vencimiento -->
          <v-col cols="2" sm="2" md="2" class="py-0">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaVtoSelected"
                  label="Fecha vencimiento"
                  :append-icon="calendarIcon"
                  v-bind="attrs"
                  outlined
                  dense
                  hint="Formato DD/MM/AAAA"
                  v-mask="'##/##/####'"
                  onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                  @blur="fechaVto = parseDateToIso(fechaVtoSelected)"
                  v-on="on"
                  :rules="
                    //si es editar se valida con fecha de alta, si es nuevo se valida con fecha de hoy
                    itemCotizador != null
                      ? rules.required.concat(
                          rules.validDate,
                          new Date(parseDateToIso(itemCotizador.fechaAlta)) <=
                            new Date(parseDateToIso(fechaVtoSelected)) ||
                            'La fecha debe ser igual o mayor a la fecha de alta'
                        )
                      : rules.required.concat(
                          rules.validDate,
                          new Date(
                            parseDateToIso(
                              formatDate(
                                new Date(new Date()).toISOString().substr(0, 10)
                              )
                            )
                          ) <= new Date(parseDateToIso(fechaVtoSelected)) ||
                            'La fecha debe ser igual o mayor a la fecha de hoy'
                        )
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaVto"
                no-title
                @change="fechaVtoSelected = formatDate(fechaVto)"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal"> Cancelar </v-btn>
      <v-btn
        type="submit"
        :disabled="!isFormValid || itemsIntegrante.length === 0"
        :loading="loadingSaveBtn"
        form="formCotizador"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-card-actions>
    <!-- dialog integrante -->
    <v-dialog
      v-if="openModalIntegrante"
      v-model="openModalIntegrante"
      max-width="70%"
      @keydown.esc="toggleModalIntegrante"
      persistent
    >
      <NuevoIntegranteCotizador
        @addIntegrante="addIntegrante"
        :fecha="fecha"
        :osId="convenioSelected"
        :planId="planSelected"
        :enviarCategoria="enviarCategoria"
        :integrantes="itemsIntegrante"
        @toggleModalIntegrante="toggleModalIntegrante"
      ></NuevoIntegranteCotizador>
    </v-dialog>
    <!-- dialog envio mail -->
    <v-dialog
      v-if="banderaOpenModalSendMail"
      v-model="banderaOpenModalSendMail"
      max-width="45%"
      @keydown.esc="toggleModalSendEmail"
      persistent
    >
      <EnviarEmailCotizacion
        @closeModal="closeModal"
        :dataToSendEmail="dataToSendEmail"
      ></EnviarEmailCotizacion>
    </v-dialog>
    <!-- dialog regla comercial -->
    <v-dialog
      v-if="openModalReglaComercial"
      v-model="openModalReglaComercial"
      max-width="65%"
      @keydown.esc="toggleReglaComercial"
      persistent
    >
      <NuevaReglaComercial
        @addReglaComercial="addReglaComercial"
        :fecha="fecha"
        :integrantes="itemsIntegrante"
        :reglasComercialesDataTable="itemsReglasCom"
        :bonifOrRecargoSelected="bonifOrRecargoSelected"
        @toggleReglaComercial="toggleReglaComercial"
      ></NuevaReglaComercial>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      max-width="35%"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import NuevoIntegranteCotizador from "@/components/modules/afiliaciones/solicitudes-afiliacion/NuevoIntegranteCotizador.vue";
import NuevaReglaComercial from "@/components/modules/afiliaciones/solicitudes-afiliacion/NuevaReglaComercial.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import EnviarEmailCotizacion from "@/components/modules/afiliaciones/solicitudes-afiliacion/EnviarEmailCotizacion.vue";

export default {
  name: "EditCotizador",
  components: {
    NuevoIntegranteCotizador,
    NuevaReglaComercial,
    CurrencyInput,
    DeleteDialog,
    EnviarEmailCotizacion
  },
  props: {
    itemCotizador: {
      type: Object,
      required: false,
      default: null
    },
    enviarCategoria: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  directives: { mask },
  data: vm => ({
    calendarIcon: enums.icons.CALENDAR,
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK_OUTLINE,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    infoIcon: enums.icons.SNB_INFO,
    rules: rules,
    formEditTitle: "Nueva cotización",
    showDeleteModal: false,
    titleDelete: "¿Desea eliminar la regla comercial?",
    isFormValid: false,
    loadingSaveBtn: false,
    mail: null,
    telefono: null,
    nombreDestinatario: null,
    importeRedondeo: "",
    aporteEsperado: 0,
    currencyOptionsForPorcentaje: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "hidden",
      valueRange: {
        min: 0,
        max: 100
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "hidden",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    redondeoSelect: { id: 1, value: "Sin redondeo", refNum: 0 },
    redondeoItems: [
      { id: 1, value: "Sin redondeo", refNum: 0 },
      { id: 2, value: "Unidad", refNum: 1 },
      { id: 3, value: "Decena", refNum: 10 }
    ],
    rtdoCotizacion: 0,
    rtdoCotizacionFinal: 0,
    convenioSelected: null,
    itemsConvenio: [],
    planSelected: null,
    itemsPlanes: [],
    itemsVendedores: [],
    vendedoresSelected: null,
    menu2: null,
    fecha: new Date().toISOString().substr(0, 10),
    fechaSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaVto: null,
    fechaVtoSelected: null,
    menu1: null,
    itemsTipoBonif: [
      { id: "B", value: "Bonificación" },
      { id: "R", value: "Recargo" }
    ],
    bonifOrRecargoSelected: null,
    porcentaje: 0,
    calculo: "0,00",
    //reglas
    itemsReglasCom: [],
    openModalReglaComercial: false,
    itemReglaToDelete: {},
    totalReglasComerciales: 0,
    //integrante
    isDeleteIntegrante: false,
    openModalIntegrante: false,
    itemsIntegrante: [],
    itemIntegranteToDelete: {},
    totalIntegrantes: 0,
    headersIntegranteConCategoria: [
      {
        text: "Parentesco",
        align: "start",
        value: "parentesco.value",
        sortable: false
      },
      {
        text: "Categoría",
        align: "left",
        value: "categoria.value",
        sortable: false
      },
      {
        text: "Sexo",
        value: "sexo",
        align: "center",
        sortable: false
      },
      {
        text: "Edad",
        value: "edad",
        align: "right",
        sortable: false
      },
      {
        text: "Valor",
        value: "valor",
        align: "right",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    headersIntegranteSinCategoria: [
      {
        text: "Parentesco",
        align: "start",
        value: "parentesco.value",
        sortable: false
      },
      {
        text: "Sexo",
        value: "sexo",
        align: "right",
        sortable: false
      },
      {
        text: "Edad",
        value: "edad",
        align: "right",
        sortable: false
      },
      {
        text: "Valor",
        value: "valor",
        align: "right",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    headersReglasCom: [
      {
        text: "Nombre regla comercial",
        value: "reglaComercial.value",
        align: "start",
        sortable: false
      },
      {
        text: "Tipo de cálculo",
        value: "tipo.value2",
        align: "left",
        sortable: false
      },
      {
        text: "Cantidad",
        value: "cantidad",
        align: "right",
        sortable: false
      },
      {
        text: "Valor",
        value: "valor",
        align: "right",
        sortable: false
      },
      {
        text: "Importe",
        value: "importe",
        align: "right",
        sortable: false
      },
      {
        text: "Acumulable",
        value: "acumulable",
        align: "center",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    banderaOpenModalSendMail: false,
    dataToSendEmail: null,
    canSendEmail: false
  }),
  created() {
    if (this.itemCotizador != null) {
      this.formEditTitle = "Editar cotización";
      this.setCotizador(this.itemCotizador);
    }
    this.setSelects();
  },
  computed: {
    formattedItems() {
      let previousAcumulable = true;
      let firstPass = true;
      let previousItem = {};
      return this.itemsReglasCom.map(item => {
        if (previousAcumulable && firstPass) {
          item.formattedImporte = item.importe;
          firstPass = false;
        } else if (previousAcumulable && !item.acumulable) {
          //creamos una lista con los elementos que no sean el actual asi se modifican los valores a 0,00
          const itemsToEdit = this.itemsReglasCom.filter(x => x !== item);
          itemsToEdit.forEach(element => {
            element.formattedImporte = "0,00";
          });
          item.formattedImporte = item.importe;
        } else if (previousAcumulable && item.acumulable) {
          item.formattedImporte = item.importe;
        } else if (!previousAcumulable && !item.acumulable) {
          const itemToEdit = this.itemsReglasCom.find(x => x === previousItem);
          itemToEdit.formattedImporte = "0,00";
          item.formattedImporte = item.importe;
        } else if (!previousAcumulable && item.acumulable) {
          item.formattedImporte = item.importe;
        }
        previousAcumulable = item.acumulable;
        previousItem = item;
        return item;
      });
    }
  },
  methods: {
    ...mapActions({
      getConveniosSoliAfiByVigencia:
        "solicitudesAfiliacion/getConveniosSoliAfiByVigencia",
      getConveniosConFormatoImpresion:
        "solicitudesAfiliacion/getConveniosConFormatoImpresion",
      getPlanesByConvenioAndVigencia:
        "afiliaciones/getPlanesByConvenioAndVigencia",
      getVendedoresAfiXUsu: "solicitudesAfiliacion/getVendedoresAfiXUsu",
      recalcularCotizacion: "solicitudesAfiliacion/recalcularCotizacion",
      saveCotizacion: "solicitudesAfiliacion/saveCotizacion",
      validateEmailCoti: "solicitudesAfiliacion/validateEmailCoti",
      setAlert: "user/setAlert"
    }),
    setCotizador(item) {
      this.vendedoresSelected = item.venAfi;
      this.nombreDestinatario = item.nombreDestinatario;
      this.mail = item.email;
      this.telefono = item.telefono;
      this.convenioSelected = item.os;
      this.setPlanesByConvenios();
      this.planSelected = item.plan;
      this.itemsReglasCom = item.reglasComerciales;
      this.itemsIntegrante = item.integrantes;
      this.fechaSelected = item.fecha;
      this.fecha = this.parseDateToIso(item.fecha);
      this.fechaVtoSelected = item.fechaVto;
      this.aporteEsperado = item.aporteToEdit === null ? 0 : item.aporteToEdit;
      this.bonifOrRecargoSelected = item.tipoBonifRecargo;
      this.porcentaje = item.porceBonifRecargoToEdit;
      this.canSendEmail = item.envioEmail;
      this.redondeoSelect = this.redondeoItems.find(
        x => x.refNum == item.redondeo
      );
      this.importeRedondeo = item.importeRedondeo;
      this.calcularCotizacion();
    },
    async setSelects() {
      // const convenios = await this.getConveniosSoliAfiByVigencia(this.fecha);
      // this.itemsConvenio = convenios;
      const convenios = await this.getConveniosConFormatoImpresion();
      this.itemsConvenio = convenios;
      const vendedores = await this.getVendedoresAfiXUsu();
      this.itemsVendedores = vendedores;
    },
    async setPlanesByConvenios() {
      this.bonifOrRecargoSelected = null;
      this.porcentaje = 0;
      this.calculo = "0,00";
      if (this.convenioSelected) {
        this.planSelected = null;
        let payloadPlanes = {
          convId: this.convenioSelected?.id,
          fechaAlta: this.fecha,
          planActual: 0,
          incluirNoVig: false
        };
        const planesByConvenio = await this.getPlanesByConvenioAndVigencia(
          payloadPlanes
        );
        this.itemsPlanes = planesByConvenio;
        //se convierte a null cuando el bool es false para que no siga validando en la regla comercial.
        if (!this.convenioSelected.bool) this.bonifOrRecargoSelected = null;
      }
    },
    async saveEdit() {
      this.isFormValid = false;
      const data = {
        cotiCabeId: this.itemCotizador ? this.itemCotizador.cotiCabeId : 0,
        fecha: this.fechaSelected,
        fechaVto: this.fechaVtoSelected,
        nombreDestinatario: this.nombreDestinatario,
        telefono: this.telefono,
        email: this.mail,
        os: this.convenioSelected,
        plan: this.planSelected,
        venAfi: this.vendedoresSelected,
        subTotal: this.rtdoCotizacion,
        reglaComercial: this.totalReglasComerciales,
        aporteToEdit: this.aporteEsperado,
        total: this.rtdoCotizacionFinal,
        reglasComerciales: this.formattedItems,
        integrantes: this.itemsIntegrante,
        porceBonifRecargoToEdit: this.porcentaje,
        importeBonifRecargo: this.calculo,
        tipoBonifRecargo: this.bonifOrRecargoSelected ?? "",
        redondeo: this.redondeoSelect.refNum,
        importeRedondeo: this.importeRedondeo
      };
      try {
        const response = await this.saveCotizacion(data);
        if (response.status === 200) {
          this.dataToSendEmail = response.data.data;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          if (this.itemCotizador == null) {
            const res = await this.validateEmailCoti(this.convenioSelected.id);
            this.canSendEmail = res.data.data;
          }
          if (this.canSendEmail) {
            this.toggleModalSendEmail();
          } else this.closeModal();
          this.isFormValid = true;
        }
      } catch (error) {
        this.isFormValid = true;
        this.closeModal();
      }
    },
    toggleModalSendEmail() {
      this.banderaOpenModalSendMail = !this.banderaOpenModalSendMail;
    },
    addIntegrante(integrante) {
      this.itemsIntegrante.push(integrante);
      this.toggleModalIntegrante();
      this.calcularCotizacion();
    },
    addReglaComercial(regla) {
      if (!regla.acumulable && this.itemsReglasCom.length > 0) {
        setTimeout(() => {
          this.setAlert({
            type: "warning",
            message:
              "La regla comercial es no acumulable por lo que cambiarán las valorizaciones de las ingresadas anteriormente."
          });
        }, 3);
      }
      this.itemsReglasCom.push(regla);
      this.toggleReglaComercial();
      this.calcularCotizacion();
    },
    deleteReglaComercial(item) {
      this.itemReglaToDelete = item;
      this.titleDelete = "¿Desea eliminar la regla comercial?";
      this.isDeleteIntegrante = false;
      this.showDeleteModal = true;
    },
    deleteIntegrante(item) {
      this.itemIntegranteToDelete = item;
      this.isDeleteIntegrante = true;
      this.titleDelete = "¿Desea eliminar el integrante?";
      this.showDeleteModal = true;
    },
    confirmDelete() {
      //es delete regla comercial
      if (!this.isDeleteIntegrante) {
        this.itemsReglasCom = this.itemsReglasCom.filter(
          x => x !== this.itemReglaToDelete
        );
        this.itemReglaToDelete = [];
        this.showDeleteModal = false;
      }
      //es delete integrante
      if (this.isDeleteIntegrante) {
        this.itemsIntegrante = this.itemsIntegrante.filter(
          x => x !== this.itemIntegranteToDelete
        );
        this.itemIntegranteToDelete = [];
        this.showDeleteModal = false;
        if (this.itemsIntegrante.length === 0) {
          this.itemsReglasCom = [];
          this.bonifOrRecargoSelected = null;
          this.porcentaje = 0;
          this.calculo = "0,00";
          this.aporteEsperado = 0;
        }
      }
      this.calcularCotizacion();
    },
    toggleModalIntegrante() {
      this.openModalIntegrante = !this.openModalIntegrante;
    },
    toggleReglaComercial() {
      this.openModalReglaComercial = !this.openModalReglaComercial;
    },
    async calcularCotizacion() {
      if (this.itemsIntegrante.length > 0) {
        let valorizarIntegrantes = [];
        this.itemsIntegrante.forEach(item => {
          valorizarIntegrantes.push({
            osId: this.convenioSelected.id,
            planId: this.planSelected.id,
            fecha: this.fecha,
            integrantes: item
          });
        });
        const params = {
          cotiCabeId: this.itemCotizador?.cotiCabeId ?? null,
          redondeo: this.redondeoSelect?.refNum,
          valorizarReglaComercial: this.itemsReglasCom,
          aportesEsperados:
            this.aporteEsperado === null ? 0 : this.aporteEsperado,
          valorizarIntegrante: valorizarIntegrantes,
          tipoBonifRecargo: this.bonifOrRecargoSelected,
          porcentajeBonifRecargo:
            this.porcentaje === "" || this.porcentaje === null
              ? 0
              : this.porcentaje,
          fechaCotiza: this.fecha
        };
        const calculoCotizacion = await this.recalcularCotizacion(params);
        if (calculoCotizacion.status === 200) {
          this.rtdoCotizacion = calculoCotizacion.data.data.totalParcialtoShow;
          this.rtdoCotizacionFinal =
            calculoCotizacion.data.data.totalFinalToShow;
          this.totalIntegrantes =
            calculoCotizacion.data.data.valorIntegrantesToShow;
          this.totalReglasComerciales =
            calculoCotizacion.data.data.valorReglasComercialesToShow;
          this.calculo = calculoCotizacion.data.data.calculoBonifRecargoToShow;
          this.itemsIntegrante = calculoCotizacion.data.data.integrantesData;
          this.itemsReglasCom = calculoCotizacion.data.data.reglasComerciales;
          this.importeRedondeo = calculoCotizacion.data.data.importeRedondeo;
        } else {
          this.isFormValid = false;
        }
      } else {
        this.rtdoCotizacion = null;
        this.rtdoCotizacionFinal = null;
        this.totalReglasComerciales = 0;
        this.totalIntegrantes = 0;
      }
    },
    changeBonifRecargo() {
      this.porcentaje = 0;
      this.calcularCotizacion();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.banderaOpenModalSendMail = false;
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
.totales {
  font-weight: 500;
}
</style>
