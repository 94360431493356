<template>
  <v-container class="pt-0">
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <v-row>
          <v-col cols="6" class="pb-0">
            <PageHeader :title="title" class="pb-1" />
          </v-col>
          <v-col v-if="canGoConfigCotizador" cols="6" class="pt-3">
            <v-tooltip left class="pt-10">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigCotizador()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a configuración de cotizador</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto mb-3 pt-0" max-width="1200" outlined>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0 pb-2"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="in-out">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                dense
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Numero cotizador -->
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      v-model.trim="nroCotizador"
                      label="N° de cotización"
                      outlined
                      clearable
                      dense
                      v-mask="'#########'"
                    ></v-text-field>
                  </v-col>
                  <!-- Nombre -->
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      v-model.trim="nombreDestinatario"
                      label="Nombre destinatario"
                      outlined
                      clearable
                      dense
                      :disabled="nroCotizador !== null && nroCotizador !== ''"
                      :rules="
                        nombreDestinatario
                          ? rules.required.concat([
                              rules.requiredTrim(nombreDestinatario),
                              rules.maxLength(nombreDestinatario, 40)
                            ])
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Vendedor -->
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      v-model="vendedoresSelected"
                      :items="itemsVendedores"
                      label="Vendedores"
                      item-text="value"
                      item-value="id"
                      :disabled="nroCotizador !== null && nroCotizador !== ''"
                      return-object
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" class="py-0">
                    <v-autocomplete
                      v-model="tipoFechaSelected"
                      :items="itemsTiposFecha"
                      return-object
                      label="Tipo fecha"
                      item-text="value"
                      item-value="id"
                      :disabled="nroCotizador !== null && nroCotizador !== ''"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- fecha desde -->
                  <v-col cols="4" sm="4" md="4" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          :label="tipoFechaSelected.value + ' desde'"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          :disabled="
                            nroCotizador !== null && nroCotizador !== ''
                          "
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="
                            fechaDesdeSelected
                              ? rules.validDate.concat(
                                  new Date(
                                    parseDateToIso(fechaDesdeSelected)
                                  ) <=
                                    new Date(
                                      parseDateToIso(fechaHastaSelected)
                                    ) || 'Formato incorrecto'
                                )
                              : []
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha hasta -->
                  <v-col cols="4" sm="4" md="4" class="py-0">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :disabled="nroCotizador !== null"
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          :label="tipoFechaSelected.value + ' hasta'"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          :disabled="
                            nroCotizador !== null && nroCotizador !== ''
                          "
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          v-on="on"
                          :rules="
                            fechaHastaSelected
                              ? rules.validDate.concat(
                                  new Date(
                                    parseDateToIso(fechaDesdeSelected)
                                  ) <=
                                    new Date(
                                      parseDateToIso(fechaHastaSelected)
                                    ) || 'Formato incorrecto'
                                )
                              : []
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col col="3" class="py-0 pt-3" align="right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsCotizador"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            item-key="cotiCabeId"
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModalEditCotizador()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalEditCotizador(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar cotización</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteCotizador(item.cotiCabeId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar cotización</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="20"
                    @click="printCotizacion(item.cotiCabeId)"
                  >
                    {{ pdfIcon }}
                  </v-icon>
                </template>
                <span>Imprimir pdf</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12" md="3">
                    <strong>Fecha alta:</strong>
                    {{ item.fechaAlta }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Usuario alta:</strong>
                    {{ item.usuAlta }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Fecha modificación:</strong>
                    {{ item.fechaModi }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Usuario modificación:</strong>
                    {{ item.usuModi }}
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="6" md="7" align="right" class="py-3">
            <v-tooltip top max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="fontsize"
                  v-bind="attrs"
                  outlined
                  :disabled="itemsCotizador.length === 0"
                  @click="exportExcelModelo"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-dialog
          v-if="openModalCotizadorEdit"
          v-model="openModalCotizadorEdit"
          max-width="80%"
          @keydown.esc="closeAndReload"
          persistent
        >
          <EditCotizador
            :itemCotizador="itemToEdit"
            :enviarCategoria="enviarCategoria"
            @closeAndReload="closeAndReload"
          ></EditCotizador>
        </v-dialog>
        <DeleteDialog
          :titleProp="titleDelete"
          :maxWidth="'35%'"
          :isLoading="loadingBtnDelete"
          :openDelete.sync="showDeleteModal"
          @onDeleteItem="confirmDelete()"
        />
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-0 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mask } from "vue-the-mask";
import EditCotizador from "@/components/modules/afiliaciones/solicitudes-afiliacion/EditCotizador.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "Cotizador",
  components: {
    FiltersSelected,
    DeleteDialog,
    PageHeader,
    Ayuda,
    EditCotizador
  },
  directives: { mask },
  data: vm => ({
    titleDelete: enums.titles.DELETE_COTIZACION,
    searchIcon: enums.icons.SEARCH,
    pdfIcon: enums.icons.PDF_EXPORT,
    optionCode: enums.webSiteOptions.COTIZADOR,
    editIcon: enums.icons.EDIT,
    settingsIcon: enums.icons.SETTINGS,
    deleteIcon: enums.icons.DELETE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    allowedActions: null,
    rules: rules,
    showExpand: false,
    title: enums.titles.COTIZADOR,
    showHelp: false,
    canCreate: false,
    canEdit: false,
    canDelete: false,
    canGoConfigCotizador: false,
    search: "",
    isLoading: false,
    expanded: [],
    //filters
    nroCotizador: null,
    tipoFechaSelected: { id: 1, value: "Fecha cotización" },
    itemsTiposFecha: [],
    isFormValid: false,
    filtersApplied: [],
    showFilters: true,
    itemsVendedores: [],
    vendedoresSelected: null,
    nombreDestinatario: null,
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .substr(0, 10),
    fechaDesdeSelected: vm.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 10)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: null,
    fechaHastaSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaHasta: new Date().toISOString().substr(0, 10),
    menu2: null,
    headers: [
      {
        text: "N° de cotización",
        align: "start",
        value: "cotiCabeId",
        sortable: false
      },
      {
        text: "Nombre destinatario",
        align: "start",
        value: "nombreDestinatario",
        sortable: false
      },
      {
        text: "Vendedor",
        align: "start",
        value: "venAfi.value",
        sortable: false
      },
      {
        text: "Convenio",
        align: "start",
        value: "os.value",
        sortable: false
      },
      {
        text: "Plan",
        align: "start",
        value: "plan.value",
        sortable: false
      },
      {
        text: "Fecha cotización",
        align: "start",
        value: "fecha",
        sortable: false
      },
      {
        text: "Fecha vencimiento",
        align: "start",
        value: "fechaVto",
        sortable: false
      },
      {
        text: "Resultado cotización",
        align: "right",
        value: "total",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", align: "end", value: "data-table-expand" }
    ],
    itemsCotizador: [],
    itemToEdit: null,
    openModalCotizadorEdit: false,
    showDeleteModal: false,
    loadingBtnDelete: false,
    idToDelete: null,
    enviarCategoria: null
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.setPermisos();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setSelects();
    this.applyFilters();
  },
  methods: {
    ...mapActions({
      getVendedoresAfiXUsu: "solicitudesAfiliacion/getVendedoresAfiXUsu",
      getCotizaciones: "solicitudesAfiliacion/getCotizaciones",
      deleteCotizacion: "solicitudesAfiliacion/deleteCotizacion",
      imprimirCotizacion: "solicitudesAfiliacion/imprimirCotizacion",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_COTIZADOR:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_COTIZADOR:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_COTIZADOR:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.CONFIGURACION_COTIZADOR:
            this.canGoConfigCotizador = true;
            break;
          default:
            break;
        }
      });
    },
    async setSelects() {
      const vendedores = await this.getVendedoresAfiXUsu();
      this.itemsVendedores = vendedores;
      this.itemsTiposFecha = [
        { id: 1, value: "Fecha cotización" },
        { id: 2, value: "Fecha vencimiento" },
        { id: 3, value: "Fecha alta" }
      ];
    },
    async applyFilters() {
      this.isLoading = true;
      this.showFilters = false;
      this.customizeFiltersApplied();
      const filters = {
        nroCotizacion: this.nroCotizador,
        venAfiId: this.nroCotizador ? null : this.vendedoresSelected?.id,
        nombreDestinatario: this.nroCotizador ? null : this.nombreDestinatario,
        tipoFecha:
          this.nroCotizador ||
          (!this.fechaDesdeSelected && !this.fechaHastaSelected)
            ? null
            : this.tipoFechaSelected?.id,
        fechaDesde: this.nroCotizador ? null : this.fechaDesde,
        fechaHasta: this.nroCotizador ? null : this.fechaHasta
      };
      try {
        const response = await this.getCotizaciones(filters);
        this.itemsCotizador = response.cotizaciones;
        this.enviarCategoria = response.enviarCategoria;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.nroCotizador) {
        this.filtersApplied.splice(0, 1, {
          key: "nroCotizador",
          label: "Numero cotizador",
          model: this.nroCotizador
        });
      } else {
        if (this.nombreDestinatario) {
          this.filtersApplied.splice(0, 1, {
            key: "nombreDestinatario",
            label: "Nombre destinatario",
            model: this.nombreDestinatario
          });
        }
        if (this.vendedoresSelected) {
          this.filtersApplied.splice(1, 1, {
            key: "vendedoresSelected.value",
            label: "Vendedores",
            model: this.vendedoresSelected.value
          });
        }
        if (this.fechaDesdeSelected) {
          this.filtersApplied.splice(2, 1, {
            key: "fechaDesdeSelected",
            label: this.tipoFechaSelected.value + " desde",
            model: this.fechaDesdeSelected
          });
        }
        if (this.fechaHastaSelected) {
          this.filtersApplied.splice(3, 1, {
            key: "fechaHastaSelected",
            label: this.tipoFechaSelected.value + " hasta",
            model: this.fechaHastaSelected
          });
        }
        if (
          !this.fechaDesdeSelected &&
          !this.fechaHastaSelected &&
          !this.vendedoresSelected &&
          !this.nombreDestinatario
        ) {
          this.filtersApplied.splice(3, 1, {
            key: "todos",
            label: "Filtros ",
            model: "Todos"
          });
        }
      }
    },
    openModalEditCotizador(item) {
      this.itemToEdit = item;
      this.openModalCotizadorEdit = true;
    },
    deleteCotizador(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      const response = await this.deleteCotizacion(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({
          type: "success",
          message: "Cotización eliminada con exito"
        });
        this.loadingBtnDelete = false;
        this.applyFilters();
      }
    },
    goToConfigCotizador() {
      this.$router.push({
        name: "ConfiguracionCotizador"
      });
    },
    exportExcelModelo() {
      let result = [];
      this.itemsCotizador?.forEach(x =>
        result.push({
          ["N° de cotización"]: x.cotiCabeId,
          ["Nombre destinatario"]: x.nombreDestinatario,
          ["Vendedor"]: x.venAfi.value,
          ["Convenio"]: x.os.value,
          ["Plan"]: x.plan.value,
          ["Fecha"]: x.fecha,
          ["Fecha vencimiento"]: x.fechaVto,
          ["Email"]: x.email,
          ["Teléfono"]: x.telefono,
          ["Total cotización"]: x.total,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Cotizador");
    },
    async printCotizacion(id) {
      const data = {
        id: id,
        tipoImpresion: "R",
        fileName: `Cotizacion`,
        browser: this.getBrowserInfo().charAt(0)
      };
      await this.imprimirCotizacion(data);
      try {
      } catch (error) {}
    },
    getBrowserInfo() {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return "IE " + (tem[1] || "");
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null)
          return tem
            .slice(1)
            .join(" ")
            .replace("OPR", "Opera");
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(" ");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    closeAndReload() {
      this.openModalCotizadorEdit = false;
      this.applyFilters();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
