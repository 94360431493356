<template>
  <v-card :loading="loadingCard">
    <v-card-title>
      <span class="pl-0 primary--text">Datos regla comercial:</span>
    </v-card-title>
    <v-card-text>
      <v-form
        v-model="isFormValid"
        ref="formReglaCom"
        form="formReglaCom"
        id="formReglaCom"
        @submit.prevent="saveReglaComercial()"
      >
        <v-row>
          <!-- reglas comerciales -->
          <v-col cols="6" class="pb-0 py-0">
            <v-autocomplete
              class="p-0"
              v-model="reglasComercialesSelected"
              :items="itemsReglasComerciales"
              label="Reglas comerciales"
              item-text="rgNom"
              item-value="rgId"
              :rules="rules.required"
              outlined
              @change="valorizarRegla(cantidad)"
              dense
            >
              <template slot="item" slot-scope="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.rgNom }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Descripción: {{ item.rgDescrip }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <!-- tipo cotizacion -->
          <v-col cols="3" class="py-0">
            <v-text-field
              v-model.trim="tipoCalculo"
              label="Tipo de cálculo"
              outlined
              disabled
              dense
              filled
              readonly
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <!-- valor -->
          <v-col cols="2" class="py-0">
            <v-text-field
              v-model.trim="valor"
              label="Valor"
              outlined
              disabled
              dense
              filled
              readonly
              :rules="rules.required"
              class="right-input"
            ></v-text-field>
          </v-col>
          <!-- cantidad -->
          <v-col cols="2" class="py-0">
            <v-text-field
              v-model.trim="cantidad"
              label="Cantidad"
              outlined
              dense
              v-mask="'###'"
              onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
              @input="valorizarRegla(cantidad)"
              :filled="tipoCalculo === 'Porcentaje'"
              :rules="rules.required"
              class="right-input"
              :disabled="tipoCalculo === 'Porcentaje'"
            ></v-text-field>
          </v-col>
          <!-- importe -->
          <v-col cols="2" class="py-0">
            <v-text-field
              v-model.trim="importe"
              label="Importe"
              outlined
              disabled
              dense
              filled
              readonly
              :rules="rules.required"
              class="right-input"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="pr-6">
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal"> Cancelar </v-btn>
      <v-btn
        type="submit"
        :disabled="!isFormValid && !importe && !valor"
        form="formReglaCom"
        color="primary"
      >
        Agregar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";

export default {
  props: {
    fecha: {
      type: String,
      required: true
    },
    integrantes: {
      type: Array,
      required: true
    },
    reglasComercialesDataTable: {
      type: Array,
      required: false,
      default: null
    },
    bonifOrRecargoSelected: {
      type: String,
      required: false,
      default: null
    }
  },
  directives: { mask },
  data() {
    return {
      rules: rules,
      mSCloseBoxIcon: enums.icons.CLOSE_BOX,
      checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
      isFormValid: false,
      cantidad: 1,
      reglasComercialesSelected: null,
      itemsReglasComerciales: [],
      importe: null,
      valor: null,
      tipoCalculo: null,
      isReglaValorizada: false,
      loadingCard: false,
      dataReglaToTable: {}
    };
  },
  created() {
    this.setSelect();
  },
  methods: {
    ...mapActions({
      getReglasComercialesCotizador:
        "solicitudesAfiliacion/getReglasComercialesCotizador",
      valorizarReglaComercial: "solicitudesAfiliacion/valorizarReglaComercial",
      setAlert: "user/setAlert"
    }),
    async setSelect() {
      const reglasComerciales = await this.getReglasComercialesCotizador(
        this.fecha
      );
      this.itemsReglasComerciales = reglasComerciales;
    },
    async valorizarRegla(cant) {
      if (this.reglasComercialesSelected && cant.toString().length < 4) {
        this.loadingCard = true;
        const resReglaComercial = await this.valorizarReglaComercial({
          rgId: this.reglasComercialesSelected,
          cantidad: !this.cantidad ? 1 : this.cantidad,
          integrantes: this.integrantes,
          fechaCotiza: this.fecha
        });
        this.tipoCalculo = resReglaComercial.tipo.value2;
        this.valor = resReglaComercial.valor;
        this.importe = resReglaComercial.importe;
        this.cantidad =
          this.tipoCalculo === "Porcentaje" ? 1 : resReglaComercial.cantidad;
        this.isReglaValorizada = true;
        this.dataReglaToTable = {
          cotiReglaId: 0,
          valor: this.valor,
          cantidad: this.cantidad,
          reglaComercial: resReglaComercial.reglaComercial,
          importe: this.importe,
          tipo: resReglaComercial.tipo,
          acumulable: resReglaComercial.acumulable
        };
      } else {
        this.importe = null;
        this.valor = null;
        this.tipoCalculo = null;
        this.isReglaValorizada = false;
      }
      this.loadingCard = false;
    },
    async saveReglaComercial() {
      //se valida que no haya dos reglas con el mismo nombre de regla
      if (
        this.reglasComercialesDataTable.find(
          x => x.reglaComercial.id === this.reglasComercialesSelected
        )
      ) {
        this.setAlert({
          type: "error",
          message: "Regla repetida, por favor ingrese otra regla comercial"
        });
        //aca se valida que no agregue regla de tipo porcentaje si el bonif/recargo esta seleccionado
      } else if (
        this.bonifOrRecargoSelected !== null &&
        this.bonifOrRecargoSelected !== "" &&
        this.dataReglaToTable.tipo.value1 === "P"
      ) {
        this.setAlert({
          type: "error",
          message:
            "La cotización tiene ingresada una bonificación o recargo. No es posible ingresar reglas comerciales de tipo de cálculo porcentaje. "
        });
      } else {
        this.$emit("addReglaComercial", this.dataReglaToTable);
        this.setAlert({
          type: "success",
          message: "Regla comercial agregada con éxito"
        });
      }
    },
    closeModal() {
      this.$emit("toggleReglaComercial");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
</style>
