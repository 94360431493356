<template>
  <v-card :loading="loadingCard">
    <v-card-title>
      <span class="pl-0 primary--text">Datos integrante:</span>
    </v-card-title>
    <v-card-text>
      <v-form
        v-model="isFormValid"
        ref="formIntegrante"
        form="formIntegrante"
        id="formIntegrante"
        @submit.prevent="saveIntegrante()"
      >
        <v-row>
          <v-col cols="3" class="py-0">
            <v-autocomplete
              v-model="parentescoSelected"
              :items="itemsParentescos"
              label="Parentesco"
              item-text="value"
              item-value="id"
              return-object
              :rules="rules.required"
              outlined
              @change="changeParentesco"
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col v-if="enviarCategoria" cols="2" class="py-0">
            <v-autocomplete
              v-model="categoriaSelected"
              :items="itemsCategoria"
              label="Categoría"
              item-text="value"
              item-value="id"
              outlined
              return-object
              dense
              :rules="enviarCategoria ? rules.required : []"
              @change="changeCampos"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="py-0">
            <v-autocomplete
              v-model="sexoSelected"
              :items="itemsSexo"
              label="Sexo"
              item-text="value"
              item-value="value"
              :rules="rules.required"
              @change="changeCampos"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="py-0">
            <v-text-field
              v-model.trim="edad"
              label="Edad"
              outlined
              dense
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              v-mask="'###'"
              :rules="rules.required"
              @change="changeCampos"
            ></v-text-field>
          </v-col>
          <v-col col="1" class="py-0">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  @click="valorizarIntegrante"
                  :disabled="!edad || !sexoSelected || !parentescoSelected"
                  x-large
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ loadIcon }}
                </v-icon>
              </template>
              <span>Calcular importe</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" class="py-0 pl-0">
            <v-text-field
              v-model.trim="importe"
              label="Importe"
              class="right-input"
              outlined
              disabled
              filled
              readonly
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="pr-6">
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal"> Cancelar </v-btn>
      <v-btn
        type="submit"
        :disabled="
          !isFormValid ||
            importe === null ||
            importe === '0,00' ||
            edad === '' ||
            edad === null
        "
        form="formIntegrante"
        color="primary"
      >
        Agregar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";

export default {
  props: {
    fecha: {
      type: String,
      required: false,
      default: null
    },
    osId: {
      type: Object,
      required: false,
      default: null
    },
    planId: {
      type: Object,
      required: false,
      default: null
    },
    enviarCategoria: {
      type: Boolean,
      required: false,
      default: false
    },
    integrantes: {
      type: Array,
      required: false,
      default: null
    }
  },
  directives: { mask },
  data: vm => ({
    loadIcon: enums.icons.BTN_LOAD,
    isFormValid: false,
    rules: rules,
    itemsCategoria: [],
    categoriaSelected: null,
    sexoSelected: null,
    itemsSexo: [
      {
        id: 1,
        value: "F"
      },
      { id: 2, value: "M" }
    ],
    itemsParentescos: [],
    parentescoSelected: null,
    edad: null,
    importe: null,
    isValorizado: false,
    dataIntegranteToTable: {},
    loadingCard: false
  }),
  created() {
    this.setParentesco();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getAllParentescos: "solicitudesAfiliacion/getAllParentescos",
      getCategoriaByParams: "solicitudesAfiliacion/getCategoriaByParams",
      valorizarIntegranteCotizador:
        "solicitudesAfiliacion/valorizarIntegranteCotizador"
    }),
    async setParentesco() {
      const parentescos = await this.getAllParentescos();
      this.itemsParentescos = parentescos;
    },
    async setCategoriaByParams() {
      this.categoriaSelected = null;
      const paramsCategoria = {
        fecha: this.fecha,
        osId: this.osId.id,
        planId: this.planId.id,
        integrantes: { parentesco: this.parentescoSelected }
      };
      const categorias = await this.getCategoriaByParams(paramsCategoria);
      this.itemsCategoria = categorias;
    },
    changeParentesco() {
      if (!this.enviarCategoria && this.parentescoSelected) this.importe = null;
      if (this.parentescoSelected) this.setCategoriaByParams();
      else {
        this.itemsCategoria = [];
        this.categoriaSelected = null;
      }
    },
    changeCampos() {
      this.importe = null;
    },
    async valorizarIntegrante() {
      this.isFormValid = false;
      if (this.parentescoSelected && this.sexoSelected && this.edad) {
        this.loadingCard = true;
        const dataIntegrante = {
          fecha: this.fecha,
          osId: this.osId.id,
          planId: this.planId.id,
          integrantes: {
            categoria: this.categoriaSelected ?? { id: null },
            parentesco: this.parentescoSelected,
            edad: Number(this.edad),
            sexo: this.sexoSelected
          }
        };
        const valorizarInte = await this.valorizarIntegranteCotizador(
          dataIntegrante
        );
        if (valorizarInte.status === 200) {
          this.isValorizado = true;
          this.importe = valorizarInte.data.data.valor;
          this.dataIntegranteToTable = {
            fecha: this.fecha,
            osId: this.osId,
            planId: this.planId,
            cotiDetaId: 0,
            valor: this.importe,
            categoria: this.categoriaSelected ?? { id: null },
            parentesco: this.parentescoSelected,
            edad: Number(this.edad),
            sexo: this.sexoSelected
          };
          if (this.importe === "0,00") {
            this.setAlert({
              type: "warning",
              message:
                "No es posible calcular importe para los datos ingresados. Verifique configuración de aranceles."
            });
          }
        }
        this.loadingCard = false;
      }
      this.isFormValid = true;
    },
    saveIntegrante() {
      //se valida que no haya integrantes titulares repetidos antes de guardar
      if (
        this.integrantes.find(x => x.parentesco.id === 2) &&
        this.parentescoSelected.id === 2
      ) {
        this.setAlert({
          type: "error",
          message: "Titular repetido, por favor ingrese otro parentesco"
        });
      } else {
        this.$emit("addIntegrante", this.dataIntegranteToTable);
        this.setAlert({
          type: "success",
          message: "Integrante agregado con éxito"
        });
      }
    },
    closeModal() {
      this.$emit("toggleModalIntegrante");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
</style>
