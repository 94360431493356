<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text"
          >¿Desea enviar la cotización por email?</span
        >
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal">
          No
        </v-btn>
        <v-btn color="primary" :loading="loadBtn" @click="sendEmail">
          Si
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    dataToSendEmail: {
      type: Number,
      required: true
    }
  },
  data: vm => ({
    loadBtn: false
  }),
  methods: {
    ...mapActions({
      sendEmailIndividualCotizacion:
        "solicitudesAfiliacion/sendEmailIndividualCotizacion",
      setAlert: "user/setAlert"
    }),
    async sendEmail() {
      this.loadBtn = true;
      const data = {
        codOperacion: "COTI",
        tablaId: this.dataToSendEmail,
        emailId: 0
      };
      try {
        const res = await this.sendEmailIndividualCotizacion(data);
        if (res.status === 200) {
          this.setAlert({
            type: "success",
            message: "Email enviado con éxito"
          });
          this.loadBtn = false;
          this.closeModal();
        } else {
          this.loadBtn = false;
          this.closeModal();
        }
      } catch (error) {
        this.closeModal();
        this.loadBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>
